import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import { get, map } from 'lodash'

const LINKS = [{
  title: 'L’immobilier neuf en général',
  link: ''
}, {
  title: 'L’achat',
  link: ''
}, {
  title: 'Le financement',
  link: ''
},{
  title: 'La livraison',
  link: ''
}, {
  title: 'L’investissement dans le neuf',
  link: ''
}]

const ContainerTab = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 40px 0 24px;
`

const StyledLink = styled(Link)`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px auto;
  padding: 11px 40px;
  border-radius: 25px;
  background-color: ${({ theme }) => get(theme, 'paleBlue', '#FFF')};
  text-decoration: none;
`

const LoanText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: ${({ theme }) => get(theme, 'blue', '#FFF')};
`

const Links = () => map(LINKS, ({ title, link }) => (
<StyledLink key={`${title}-${link}`} to={link} title={title}>
    <LoanText dangerouslySetInnerHTML={{ __html: title }} />
</StyledLink>
))

const FaqTab = () => (
    <ContainerTab>
      <Links />
    </ContainerTab>
)

export default FaqTab
