import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, map, isEqual } from 'lodash'

import SEO from '../../atoms/Seo'
import theme from '../../../config/theme'
import Navbar from '../../organisms/Navbar'
import FaqTab from '../../organisms/FaqTab'
import Footer from '../../organisms/NewFooter'
import FaqSearch from '../../organisms/FaqSearch'
import FaqTopSection from '../../organisms/FaqTopSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const ContentQuestions = styled.div`
  max-width: 650px;
  margin: 56px auto;
`
const Content = styled.div`
  max-width: 1100px;
  margin: auto;
`

const StyledHomeSectionTitle = styled(HomeSectionTitle)`
  margin: 40px 0 24px;
`

const QTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  transform: ${({ showAnswer }) => isEqual(showAnswer, true) ? 'rotate(0deg)' : ''};
  transition: all 0.2s ease-out;
`

const StyledHomeSectionSubtitle = styled(HomeSectionSubtitle)`
  margin-bottom: 8px;
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  position: relative;
  transition: all 0.5s ease;
  margin-bottom:${({ showAnswer }) => isEqual(showAnswer, true) ? '16px' : '8px'};
  opacity: ${({ showAnswer }) => isEqual(showAnswer, true) ? 1 : 0};
  height: ${({ showAnswer }) => isEqual(showAnswer, true) ? 'auto' : 0};
  overflow: hidden;
`

const TITLE = 'Besoin d\'aide ?'
const DESCRIPTION = 'Bienvenue dans la Foire Aux Questions !'

const QUESTIONS = [{
  title: "L'immobilier neuf en général",
  questions: [{
    question: "Qu'appelle t-on l'immobilier neuf ?",
    answer: 'L’immobilier neufs considère tous les immeubles/programmes en état futur d’achèvement. On parle alors d’achat sur plan ou de vente en l’état futur d’achèvement (VEFA). Il est régit par un contrat immobilier spécifique aux biens à construire ou en cours de travaux.'
  }, {
    question: 'C’est quoi un promoteur ?',
    answer: 'Donec rutrum congue leo eget malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada.'
  }, {
    question: 'Normes RT2012 et RE 2020, c’est quoi ?',
    answer: 'Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Nulla quis lorem ut libero malesuada feugiat.'
  }, {
    question: 'Pourquoi l’immobilier neuf est plus cher que l’ancien ?',
    answer: 'Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam id dui posuere blandit. Donec rutrum congue leo eget malesuada. Pellentesque in ipsum id orci porta dapibus. Nulla porttitor accumsan tincidunt.'
  }]
}, {
  title: "L'achat",
  questions: [{
    question: 'Comment se passe un achat dans le neuf ?',
    answer: 'Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.'
  }, {
    question: 'Je suis prêt·e à acheter, par quoi je dois commencer ?',
    answer: 'Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Sed porttitor lectus nibh. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.'
  }, {
    question: 'C’est quoi un contrat de réservation ?',
    answer: 'Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Pellentesque in ipsum id orci porta dapibus. Proin eget tortor risus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Nulla quis lorem ut libero malesuada feugiat.'
  }, {
    question: 'Est-ce-qu’il y a un dépôt de garantie ?',
    answer: 'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.'
  }, {
    question: 'Les TMA, c’est quoi ?',
    answer: 'Donec sollicitudin molestie malesuada. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.'
  }]
}, {
  title: 'Le financement',
  questions: [{
    question: 'Comment obtenir un prêt immobilier ?',
    answer: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Proin eget tortor risus.'
  }, {
    question: 'Puis-je faire appel à un courtier ?',
    answer: 'Donec rutrum congue leo eget malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada.'
  }, {
    question: 'Et si je n’ai pas l’accord de ma banque ?',
    answer: 'Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Nulla quis lorem ut libero malesuada feugiat.'
  }, {
    question: 'Faut-il un apport financier ?',
    answer: 'Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam id dui posuere blandit. Donec rutrum congue leo eget malesuada. Pellentesque in ipsum id orci porta dapibus. Nulla porttitor accumsan tincidunt.'
  }, {
    question: 'Les frais de notaire, c’est quoi ?',
    answer: 'Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh.'
  }]
}, {
  title: 'La livraison',
  questions: [{
    question: 'Quels sont les délais de livraison ?',
    answer: 'Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.'
  }, {
    question: 'Quelles sont les garanties à la livraison ? ',
    answer: 'Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Sed porttitor lectus nibh. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.'
  }, {
    question: 'Peut-il y avoir des retards ?',
    answer: 'Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Pellentesque in ipsum id orci porta dapibus. Proin eget tortor risus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Nulla quis lorem ut libero malesuada feugiat.'
  }, {
    question: 'Ma situation a changé, que puis-je faire ? ',
    answer: 'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.'
  }]
}, {
  title: 'L’investissement dans le neuf',
  questions: [{
    question: 'Qu’est-ce qu’un investissement immobilier locatif ?',
    answer: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Proin eget tortor risus.'
  }, {
    question: 'Pourquoi choisir la VEFA pour votre investissement immobilier neuf ?',
    answer: 'Donec rutrum congue leo eget malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada.'
  }, {
    question: 'Comment calculer la rentabilité d’un investissement immobilier locatif ?',
    answer: 'Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Nulla quis lorem ut libero malesuada feugiat.'
  }, {
    question: 'Qu’est-ce que le projet du Grand Paris Express ?',
    answer: 'Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur aliquet quam id dui posuere blandit. Donec rutrum congue leo eget malesuada. Pellentesque in ipsum id orci porta dapibus. Nulla porttitor accumsan tincidunt.'
  }]
}]

const Question = ({ question, answer }) => {
  const [showAnswer, updateShowAnswer] = useState(false)
  const toggleShowAnswer = useCallback(evt => {
    evt.stopPropagation()

    return updateShowAnswer(!showAnswer)
  }, [updateShowAnswer, showAnswer])

  return (
    <div>
      <QTitle
        outline={isEqual(showAnswer, false)}
        onClick={toggleShowAnswer}
        showAnswer={showAnswer}>
        <StyledHomeSectionSubtitle>{question}</StyledHomeSectionSubtitle>
        <Icon
          icon='angle-up'
          color={get(theme, 'blue')}
          showAnswer={showAnswer} />
      </QTitle>
     <StyledHomeSectionDescription
        showAnswer={showAnswer}>{answer}
      </StyledHomeSectionDescription>
    </div>
  )
}

Question.propTypes = {
  answer: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired
}

const Questions = () => {
  return (
    <ContentQuestions>
      {map(QUESTIONS, ({ title, questions }) => {
        return (
          <div>
            <StyledHomeSectionTitle>{title}</StyledHomeSectionTitle>
            {map(questions, ({ question, answer }) => {
              return (
                <Question question={question} answer={answer} />
              )
            })}
          </div>
        )
      })}
    </ContentQuestions>
  )
}

const FaqPage = () => {
  return (
    <>
      <SEO
      title={TITLE}
      description={DESCRIPTION} />
      <Navbar />
      <FaqTopSection />
      <Content>
        <FaqTab />
        <FaqSearch />
        <Questions />
      </Content>
      <Footer noImage={true} noForm={true} />
    </>
  )
}

export default FaqPage
