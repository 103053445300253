import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: auto;
  min-height: 450px;

  ${media.lessThan('sm')`
    padding-top: 100px;
    padding-bottom: 80px;
  `}

  ${media.greaterThan('xl')`
    max-width: 680px;
    margin-right: 55%;
    padding-left: 40px;
  `}
`

const StyledSubtitle = styled(Subtitle)`
  ${media.greaterThan('xl')`
    padding-right: 32px;
  `}
`

const StyledHeader = styled(Header)`
  margin-bottom: 8px;
`

const Under = styled(Header)`
  font-size: 29px;
  margin-top: 0;
  margin-bottom: 8px;
`

const faq = () => (
  <>
    <StyledContainer>
      <StyledHeader>
        Besoin d'aide ?
      </StyledHeader>
      <Under>
        Bienvenue dans la Foire Aux Questions !
      </Under>
      <StyledSubtitle>
        Nous avons réunis ici les questions les plus générales. Et si vous ne trouvez pas de solutions ici, n’hésitez pas à nous contacter.
      </StyledSubtitle>
    </StyledContainer>
  </>
)

export default faq
