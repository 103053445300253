import React from 'react'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from '../../../config/theme'
import styled from 'styled-components'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  border-bottom: 1px solid  ${({ theme }) => get(theme, 'lightOrange', '#fff')};
  width: 100%;
  padding: 24px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`

const Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  font-size: 30px;
  color: ${({ theme }) => get(theme, 'otherBlue', '#fff')};
`

const FaqSearch = () => (
  <Container>
    <Icon
      icon='search'
      color={get(theme, 'blue')} />
    <StyledHomeSectionDescription>Rechercher un thème par mot clé </StyledHomeSectionDescription>
  </Container>
)

export default FaqSearch
