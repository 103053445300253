import React from 'react'
import styled from 'styled-components'

import Faq from '../../molecules/Faq'
import media from '../../../utils/media'
import FaqImage from '../../molecules/FaqImage'
import TopSection from '../../molecules/TopSection'

const StyledFaqImage = styled(FaqImage)`
  z-index: -1;
  display: none;
  
  ${media.greaterThan('xl')`
    display: block;
    height: 450px; 
    top: 157px;
    right: -30px;
  `}
`

const StyledTopSection = styled(TopSection)`
  min-height: 450px;
`

const FaqTopSection = () => (
  <StyledTopSection component={StyledFaqImage}>
    <Faq />
  </StyledTopSection>
)

export default FaqTopSection
