import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'

const Subtitle = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  margin: 16px 0;
  line-height: normal;
  text-shadow: 0 3px 6px rgba(18, 59, 178, 0.79);
  color: ${({ theme }) => get(theme, 'white', '#FFF')};

  ${media.greaterThan('sm')`
    font-size: 18px;
  `}
`

export default Subtitle
